window.addEventListener("heyflow-screen-view", (event) => {
    try {
        if (event.detail.screenName === "registration") {
            var ipAddressField = document.getElementById("ipaddress");
            fetch("https://checkip.amazonaws.com/")
            .then((res) => res.text())
            .then((data) => {
                if (ipAddressField) {
                    ipAddressField.value = data.replace("\n", "");
                }
            });
        }
    } catch (err) {}
});

window.addEventListener("heyflow-submit", (event) => {
    // console.log('heyflow submit:', event.detail);
});

window.addEventListener("heyflow-init", (event) => {
    //console.log('heyflow init:', event.detail);
});

document.addEventListener("DOMContentLoaded", function () {
    
    
 
    
    // Retrieve the company variable from URL or global data
    const urlParams = new URLSearchParams(window.location.search);
    let company = urlParams.get("company") || "default"; // Use 'default' if no company is provided

    // Use only the first word of the company variable
    company = company.split(" ")[0];

    // Define iframe sources for each company
    const content = {
         Naturvel: {
            imprint: "https://www.123-tongain.com/sc/todo=show_html&html_pk=17",
            privacy: "https://www.123-tongain.com/sc/todo=show_html&html_pk=18",
            terms: "https://www.123-tongain.com/sc/todo=show_html&html_pk=13",
        },
        toleadoo: {
            imprint: "https://www.kdo-pournous.com/sc/todo=show_html&html_pk=19",
            privacy: "https://www.kdo-pournous.com/sc/todo=show_html&html_pk=20",
            terms: "https://www.kdo-pournous.com/sc/todo=show_html&html_pk=16",
        },
        default: {
            imprint: "https://budgetefficace.com/imprint-budgetefficace",
            privacy: "https://budgetefficace.com/privacypolicy-budgetefficace",
            terms: "https://budgetefficace.com/terms-and-conditions-budgetefficace",
        },
        };

        // Set iframe sources based on the company variable
        const companyContent = content[company];

        document.getElementById("privacy-content").innerHTML = `<iframe src="${companyContent.privacy}" frameborder="0"></iframe>`;
        document.getElementById("terms-content").innerHTML = `<iframe src="${companyContent.terms}" frameborder="0"></iframe>`;
        document.getElementById("imprint-content").innerHTML = `<iframe src="${companyContent.imprint}" frameborder="0"></iframe>`;

        // Modal functionality
        const modals = {
            privacy: document.getElementById("privacy-modal"),
            terms: document.getElementById("terms-modal"),
            imprint: document.getElementById("imprint-modal")
        };

        document.getElementById("privacy-link").onclick = function () {
            modals.privacy.style.display = "block";
        };

        // If there is no optin checkbox on your flow, delete this part from here...
        document.getElementById("privacy-link-chbx").onclick = function () {
            modals.privacy.style.display = "block";
        };
        //...until here.

        document.getElementById("terms-link").onclick = function () {
            modals.terms.style.display = "block";
        };
        document.getElementById("imprint-link").onclick = function () {
            modals.imprint.style.display = "block";
        };

        document.getElementById("close-privacy").onclick = function () {
            modals.privacy.style.display = "none";
        };

        document.getElementById("close-terms").onclick = function () {
            modals.terms.style.display = "none";
        };
        document.getElementById("close-imprint").onclick = function () {
            modals.imprint.style.display = "none";
        };

        // Close modal if clicked outside of content
        window.onclick = function (event) {
            if (event.target == modals.privacy) {
                modals.privacy.style.display = "none";
            }
            if (event.target == modals.terms) {
                modals.terms.style.display = "none";
            }
            if (event.target == modals.imprint) {
                modals.imprint.style.display = "none";
            }
        };
        
        
        
        
        
        

        // Form validation for the checkbox
        const form = document.querySelector("form"); // Assuming you have a form element
        const checkbox = document.getElementById("optin");
        const errorMessage = document.getElementById("checkbox-error");

        // Handle form submission
        form.addEventListener("submit", function (event) {
            let isValid = true;

            // Validate checkbox
            if (!checkbox.checked) {
                // Prevent form submission if checkbox is not checked
                isValid = false;
                checkbox.classList.add("error");
                errorMessage.textContent = "Vous devez accepter les conditions pour continuer.";
                errorMessage.classList.add("visible");
            }

            // Validate gender selection
            const genderOptions = document.querySelectorAll('input[data-label="Gender"]');
            const genderError = document.getElementById("gender-error");
            const genderSelected = Array.from(genderOptions).some(option => option.checked);

            if (!genderSelected) {
                isValid = false;
                genderError.style.display = "block";
            } else {
                genderError.style.display = "none";
            }
            if (!isValid) {
                event.preventDefault();
            }
            
          
        });

        // Clear error when the checkbox is checked
        checkbox.addEventListener("change",
            function () {
                if (checkbox.checked) {
                    // Remove error styles when checkbox is checked
                    checkbox.classList.remove("error");
                    errorMessage.classList.remove("visible");
                }
            });

        // Clear gender error on selection
        const genderOptions = document.querySelectorAll('input[data-label="Gender"]');
        genderOptions.forEach(option => {
            option.addEventListener("change", function () {
                const genderError = document.getElementById("gender-error");
                console.log("Gender option selected. Hiding error message."); // Debugging log
                genderError.style.display = "none";
            });
        });
        
        
        
        
     
    
 const ageField = document.querySelector('input[data-variable="age"]'); // Age input
const dobField = document.querySelector('input[name="dateofbirth"]'); // Date of birth input

function updateDOB() {
	const formattedValue = ageField.value.trim();
	const [dd, mm, yyyy] = formattedValue.split(".").map(str => str.padStart(2, '0'));

	// Validate parts before setting the value
	if (dd && mm && yyyy && dd.length === 2 && mm.length === 2 && yyyy.length === 4) {
		const convertedDate = `${yyyy}-${mm}-${dd}`;
		dobField.value = convertedDate;
		console.log("Converted date:", dobField.value);
	} else {
		dobField.value = "";
		console.warn("Invalid date format entered.");
	}
}

// Update DOB when the age field changes
if (ageField) {
	ageField.addEventListener("input", updateDOB);
	ageField.addEventListener("change", updateDOB);
}

    
    

    
    
    

    });